import type { Logger } from '@adapt-arch/utiliti-es';
import { type ExtraParams, LogLevel } from '@adapt-arch/utiliti-es';
import { type ILogger } from 'oidc-client-ts';

export class LogAdapter implements ILogger {
  private readonly _logger: Logger;

  constructor(logger: Logger) {
    this._logger = logger;
  }

  private log(level: LogLevel, args: unknown[]): void {
    const msg = (args && args.length > 0 ? args[0]?.toString() : undefined) || level.toString();
    const logParams: ExtraParams = {};

    for (let i = 0; i < args.length; i++) {
      logParams[`${i}`] = args[i]?.toString() || '';
    }

    this._logger.log(level, msg, undefined, logParams);
  }

  // @inheritDoc
  debug(...args: unknown[]): void {
    this.log(LogLevel.Debug, args);
  }

  // @inheritDoc
  info(...args: unknown[]): void {
    this.log(LogLevel.Information, args);
  }

  // @inheritDoc
  warn(...args: unknown[]): void {
    this.log(LogLevel.Warning, args);
  }

  // @inheritDoc
  error(...args: unknown[]): void {
    this.log(LogLevel.Error, args);
  }
}
